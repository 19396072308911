<div *ngIf="!disableContent">
  <app-progress-indicator *ngIf="isListsLoading$ | async" />

  <!-- display only when loading is finished and project member has value -->
  <div *ngIf="(isListsLoading$ | async) === false">
    <div class="content large middle">
      <!-- main content: show selected list -->
      <ng-container *ngIf="(list$ | async) as list">
        <div class="list-editor" #listEditor>
          <mat-toolbar class="more-space-bottom">
            <mat-toolbar-row>
              <h3
                class="mat-headline-6"
                [matTooltip]="list.labels |appStringifyStringLiteral:'all'"
                matTooltipPosition="above"
                data-cy="label-title">
                {{ list.labels | appStringifyStringLiteral }}
              </h3>
              <span class="fill-remaining-space"></span>
              <p
                class="mat-body"
                [matTooltip]="list.comments | appStringifyStringLiteral"
                matTooltipPosition="above"
                data-cy="comment-title">
                {{ list.comments | appStringifyStringLiteral | appTruncate:64 }}
              </p>
              <span class="fill-remaining-space"></span>

              <span
                class="project-actions"
                *ngIf="(projectBaseService.project$ | async)?.status && (projectBaseService.isAdmin$ | async)">
                <p class="mat-caption space-reducer">Controlled vocabulary configuration</p>
                <button
                  color="primary"
                  mat-button
                  (click)="$event.stopPropagation(); editList(list)"
                  matTooltip="Edit controlled vocabulary info"
                  data-cy="edit-button">
                  <mat-icon>edit</mat-icon>
                  Edit
                </button>
                <button
                  color="warn"
                  mat-button
                  class="delete"
                  (click)="$event.stopPropagation(); askToDeleteList(list)"
                  matTooltip="Delete controlled vocabulary"
                  data-cy="delete-button">
                  <mat-icon>delete</mat-icon>
                  Delete
                </button>
              </span>
            </mat-toolbar-row>
          </mat-toolbar>

          <app-list-item
            *ngIf="((isListsLoading$ | async) === false) && (projectBaseService.project$ | async) as project"
            [rootNodeIri]="list.id"
            [projectUuid]="project.id"
            [isAdmin]="projectBaseService.isAdmin$ | async" />
        </div>
      </ng-container>
    </div>
  </div>
</div>

<app-status *ngIf="disableContent" [status]="204" />
